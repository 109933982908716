// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-js": () => import("/home/circleci/stuart/src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-categories-js": () => import("/home/circleci/stuart/src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-templates-single-category-js": () => import("/home/circleci/stuart/src/templates/singleCategory.js" /* webpackChunkName: "component---src-templates-single-category-js" */),
  "component---src-templates-blog-list-js": () => import("/home/circleci/stuart/src/templates/blogList.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-pages-404-js": () => import("/home/circleci/stuart/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/home/circleci/stuart/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("/home/circleci/stuart/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-js": () => import("/home/circleci/stuart/src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */)
}

