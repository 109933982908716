module.exports = [{
      plugin: require('/home/circleci/stuart/node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/circleci/stuart/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-66600089-2"},
    },{
      plugin: require('/home/circleci/stuart/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/circleci/stuart/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"showCaptions":true},
    },{
      plugin: require('/home/circleci/stuart/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
